import React from "react"
import styled from "styled-components"

import AppLayout from "@components/layout/App"
import CAREER_PHILOSOPHY_CONTENT from "@content/career-philosophy.json"

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100%;
  padding: 0 24px;
`

const TitleContainer = styled.div`
  padding: 2rem 0;
  text-align: center;

  @media (max-width: 1023px) {
    padding: 1rem 0;
  }
`

const SectionContainer = styled.div`
  padding: 2rem 0;
  text-align: center;
  width: 100%;

  @media (max-width: 1023px) {
    padding: 1rem 0;
    
    > h2 {
      color: #702082;
    }
  }
`

const SectionFlexContainer = styled.div`
  display: flex;
  flex-direction: ${props => props.isMobile ? "column" : "row"};
  
  justify-content: space-between;
  column-gap: 16px;
  row-gap: 16px;
  
  text-align: start;
`

const SectionFlexItem = styled.div`
  flex: 1 0;
  
  border: 4px solid #FFB81C;
  
  > div {
    padding: 24px;

    h3 {
      font-size: 18px;
    }    
    .title-index {
      margin: 15px 0px;
      font-size: 32px;
      color: #FFB81C;
    }
    .description {
      font-size: 12px;
    }
  }
`

const SubHeader = styled.p`
  color: #666;
  font-size: 20px;
  
  @media (max-width: 1023px) {
    color: #2c2c2c;
    font-size: 16px;
    line-height: 1.5;
  }
`

const DesktopContent = styled.div`
  @media (max-width: 1023px) {
    display: none;
  }
`

const MobileContent = styled.div`
  display: none;

  @media (max-width: 1023px) {
    display: block;
  }
`

export default function CareerPhilosophyPage() {

  return (
    <AppLayout>
      <PageContainer>
        <TitleContainer>
          <h1>GloboBank's Career Philosophy</h1>
          <SubHeader>
            Career management at GloboBank is intended to provide associates
            with a means to achieve their career aspirations. Built on a
            platform of transparency and shared responsibility, this process
            provides local flexibility, supports diversity of work experience
            and requires universal commitment to ensure success for all.
          </SubHeader>
        </TitleContainer>

        <hr style={{ alignSelf: "stretch", marginLeft: 0, marginRight: 0 }} />

        <SectionContainer style={{ paddingBottom: "16px" }}>
          <h2>Guiding Principles</h2>

          <DesktopContent>
            <SectionFlexContainer>
              {CAREER_PHILOSOPHY_CONTENT.map((cp, index) => {
                return (
                  <SectionFlexItem>
                    <div>
                      <h3 className="title-index">{index + 1}</h3>
                      <h3>{cp.title}</h3>
                      <p className="description">{cp.description}</p>
                    </div>
                  </SectionFlexItem>
                )
              })}
            </SectionFlexContainer>
          </DesktopContent>

          <MobileContent>
            <SectionFlexContainer isMobile>
              {CAREER_PHILOSOPHY_CONTENT.map((cp, index) => {
                return (
                  <SectionFlexItem>
                    <div style={{display: "flex", padding: "8px 16px"}}>
                      <div style={{flex: "1 0"}}>
                        <h3 className="title">{index + 1}</h3>
                      </div>

                      <div style={{flex: "6 0"}}>
                        <h3>{cp.title}</h3>
                        <p>{cp.description}</p>
                      </div>
                    </div>
                  </SectionFlexItem>
                )
              })}
            </SectionFlexContainer>
          </MobileContent>


        </SectionContainer>
      </PageContainer>
    </AppLayout>
  )
}
